import classNames from 'classnames';
import { useConfig } from 'contexts/configContext';
import { NavLink } from 'react-router-dom';

const NAVLINKPATH = {
  Transact: '/transact',
  Bridge: '/bridge',
  Stake: '/stake'
};

const NavLinks = () => {
  const { NETWORK_NAME } = useConfig();
  const networkUrlParam = `/${NETWORK_NAME.toLowerCase()}`;

  const isActiveTransactPage = window.location.pathname.includes(
    NAVLINKPATH.Transact
  );
  const isActiveBridgePage = window.location.pathname.includes(
    NAVLINKPATH.Bridge
  );
  const isActiveStakePage = window.location.pathname.includes(
    NAVLINKPATH.Stake
  );
  return (
    <div className="ml-5 flex flex-row justify-between w-128 items-center text-base font-red-hat-text">
      {NETWORK_NAME.toLowerCase() === 'calamari' && (
        <NavLink
          to={`${networkUrlParam}${NAVLINKPATH.Transact}`}
          className={classNames('py-3 text-center navLink', {
            'gradient-text': isActiveTransactPage,
            'text-nav': !isActiveTransactPage
          })}>
          MantaPay
        </NavLink>
      )}
      <NavLink
        to={`${networkUrlParam}${NAVLINKPATH.Bridge}`}
        className={classNames('py-3 text-center navLink', {
          'gradient-text': isActiveBridgePage,
          'text-nav': !isActiveBridgePage
        })}>
        Bridge
      </NavLink>

      <NavLink
        to={`${networkUrlParam}${NAVLINKPATH.Stake}`}
        className={classNames('py-3 text-center navLink', {
          'gradient-text': isActiveStakePage,
          'text-nav': !isActiveStakePage
        })}>
        Staking
      </NavLink>
      <a
        href="https://forum.manta.network/"
        className="py-3 text-nav text-center navLink"
        target="_blank"
        rel="noreferrer">
        Govern
      </a>
      <a
        href={`https://${NETWORK_NAME.toLowerCase()}.subscan.io/`}
        className="py-3 text-nav text-center navLink"
        target="_blank"
        rel="noreferrer">
        Block Explorer
      </a>
    </div>
  );
};

export default NavLinks;
