// @ts-nocheck
import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import detectEthereumProvider from '@metamask/detect-provider';
import Chain from 'types/Chain';
import {
  getHasAuthToConnectEVMWalletStorage,
  setHasAuthToConnectEVMWalletStorage,
  getHasAuthToConnectEVMWalletNameStorage,
  setHasAuthToConnectEVMWalletNameStorage
} from 'utils/persistence/connectAuthorizationStorage';
import { ethers } from 'ethers';
import { showError, showWarning } from 'utils/ui/Notifications';
import { useConfig } from './configContext';

const MetamaskContext = createContext();

export const MetamaskContextProvider = (props) => {
  const config = useConfig();
  const [provider, setProvider] = useState(null);
  const [ethAddress, setEthAddress] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [isSwitchingNetwork, setIsSwitchingNetwork] = useState(false);

  const connectEVMWallet = async (
    walletName = 'metamask',
    networkName = 'MantaPacific'
  ) => {
    try {
      let _provider;
      if (walletName === 'metamask') {
        const metamask = await detectEthereumProvider({
          mustBeMetaMask: true
        });
        if (metamask) {
          setChainId(metamask.networkVersion);
          _provider = metamask;
          setProvider(_provider);
        }
      } else if (walletName === 'subwallet') {
        const subwallet = window.SubWallet;
        const decChainId = await subwallet.request({ method: 'net_version' });
        console.log('subwallet chainId', decChainId);
        subwallet.chainId = decChainId;
        setChainId(decChainId);
        _provider = subwallet;
        setProvider(_provider);
      } else if (walletName === 'talisman') {
        const talisman = window.talismanEth;
        const decChainId = await talisman.request({ method: 'net_version' });
        talisman.chainId = decChainId;
        setChainId(decChainId);
        _provider = talisman;
        setProvider(_provider);
      }
      const chain = Chain[networkName](config);
      await _provider.request({ method: 'eth_requestAccounts' });
      const accounts = await _provider.request({ method: 'eth_accounts' });
      if (!accounts.length) {
        showWarning('Did not find any account in wallet');
      }

      if (accounts[0]) {
        setEthAddress(accounts[0]);
      }
      if (_provider?.chainId !== chain.ethMetadata.chainId) {
        if (networkName === 'Ethereum' || networkName === 'MantaPacific') {
          await _provider.request({
            method: 'wallet_switchEthereumChain',
            params: [
              { chainId: Chain[networkName](config).ethMetadata.chainId }
            ]
          });
        } else {
          await _provider.request({
            method: 'wallet_addEthereumChain',
            params: [chain.ethMetadata]
          });
        }
      }
      setHasAuthToConnectEVMWalletNameStorage(walletName);
      setHasAuthToConnectEVMWalletStorage(true);
      return true;
    } catch (e) {
      console.error(e);
      if (e?.message?.includes('allowed to interact with this extension')) {
        showError('No wallet accounts are authorised');
      }
      setHasAuthToConnectEVMWalletStorage(false);
      return false;
    }
  };

  const switchNetwork = async (networkName = 'MantaPacific') => {
    try {
      setIsSwitchingNetwork(true);
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Chain[networkName](config).ethMetadata.chainId }]
      });
      const decChainId = await provider.request({ method: 'net_version' });
      console.log('after switch chainId', decChainId);
    } catch (switchError) {
      if (switchError.code === 4902) {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [Chain[networkName](config).ethMetadata]
        });
      }
    } finally {
      setIsSwitchingNetwork(false);
    }
  };

  const detectEVMWallet = async () => {
    const hasConnectedEVMWallet = getHasAuthToConnectEVMWalletStorage();
    const connectedEVMWalletName = getHasAuthToConnectEVMWalletNameStorage();
    if (hasConnectedEVMWallet) {
      if (connectedEVMWalletName === 'metamask') {
        const metamask = await detectEthereumProvider({
          mustBeMetaMask: true
        });
        console.log('metamask detected');
        if (metamask) {
          setProvider(metamask);
          await metamask.request({ method: 'eth_requestAccounts' });
          setChainId(metamask.networkVersion);
          if (metamask?.selectedAddress) {
            setEthAddress(metamask.selectedAddress);
          }
        }
      } else if (connectedEVMWalletName === 'subwallet') {
        const subwallet = window.SubWallet;
        console.log('subwallet detected');
        await subwallet.request({ method: 'eth_requestAccounts' });
        const decChainId = await subwallet.request({ method: 'net_version' }); // get chainId in decimal format
        subwallet.chainId = decChainId;
        setProvider(subwallet);
        setChainId(decChainId);
        console.log('useEffect subwallet chainId', decChainId);
        const accounts = await subwallet.request({ method: 'eth_accounts' });
        if (accounts[0]) {
          setEthAddress(accounts[0]);
        }
      } else if (connectedEVMWalletName === 'talisman') {
        try {
          const talisman = window.talismanEth;
          await talisman.request({
            method: 'eth_requestAccounts'
          });
          const decChainId = await talisman.request({ method: 'net_version' }); // get chainId in decimal format
          talisman.chainId = decChainId;
          setProvider(talisman);
          setChainId(decChainId);
          console.log('useEffect subwallet chainId', decChainId);
          const accounts = await talisman.request({ method: 'eth_accounts' });
          if (accounts[0]) {
            setEthAddress(accounts[0]);
          }
        } catch (e) {
          console.log('connect talisman error', e);
        }
      }
    }
  };

  useEffect(() => {
    detectEVMWallet();
  }, []);

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        setEthAddress(accounts[0]);
      } else {
        setEthAddress(null);
        setProvider(null);
      }
    };
    provider?.on('accountsChanged', handleAccountsChanged);
    return () => {
      provider?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, [provider]);

  useEffect(() => {
    const handleDisconnect = () => {
      setEthAddress(null);
      setProvider(null);
    };
    provider?.on('disconnect', handleDisconnect);
    return () => {
      provider?.removeListener('disconnect', handleDisconnect);
    };
  }, [provider]);

  useEffect(() => {
    const handleNetworkChanged = (chainId) => {
      setChainId(parseInt(chainId, 16).toString());
    };
    provider?.on('chainChanged', handleNetworkChanged);
    return () => {
      provider?.removeListener('chainChanged', handleNetworkChanged);
    };
  }, [provider]);

  const value = {
    provider,
    chainId,
    ethAddress,
    isSwitchingNetwork,
    switchNetwork,
    connectEVMWallet
  };

  return (
    <MetamaskContext.Provider value={value}>
      {props.children}
    </MetamaskContext.Provider>
  );
};

MetamaskContextProvider.propTypes = {
  children: PropTypes.any
};

export const useMetamask = () => ({
  ...useContext(MetamaskContext)
});
