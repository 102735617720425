// @ts-nocheck
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTxStatus } from 'contexts/txStatusContext';
import { useMetamask } from 'contexts/metamaskContext';
import getAbbreviatedName from 'utils/display/getAbbreviatedName';
import Identicon from '@polkadot/react-identicon';
import makeBlockie from 'ethereum-blockies-base64';
import CopyPasteIcon from 'components/CopyPasteIcon';
import Icon from 'components/Icon';
import { useLocation } from 'react-router-dom';
import { useWallet } from 'contexts/walletContext';
import { getHasAuthToConnectEVMWalletNameStorage } from 'utils/persistence/connectAuthorizationStorage';

type AddressDisplayProperties = {
  isPrivateAddress?: boolean;
  zkAddress: string;
  publicAddress: string;
  className?: string;
  disabled: boolean;
};

const AddressDisplay = ({
  isPrivateAddress,
  zkAddress,
  publicAddress,
  className,
  disabled
}: AddressDisplayProperties) => (
  <div
    className={classNames(
      'cursor-pointer flex items-center gap-5 justify-between border border-dark-blue bg-light-blue rounded-lg px-3 text-default w-68 h-16',
      className,
      { disabled: disabled }
    )}>
    <div className="flex flex-col">
      <div className="text-base">
        {isPrivateAddress ? 'Private' : 'Public'} Address
      </div>
      <div className="flex flex-row items-center gap-2 text-default text-opacity-60 text-sm">
        {getAbbreviatedName(isPrivateAddress ? zkAddress : publicAddress, 5, 5)}
        <CopyPasteIcon
          iconClassName="w-5 h-5"
          textToCopy={isPrivateAddress ? zkAddress : publicAddress}
        />
      </div>
    </div>
    <div className="relative right-2">
      <Icon name="greenCheck" className="text-blue-check" />
    </div>
  </div>
);

const SingleAccountDisplay = ({
  accountName,
  accountAddress,
  isAccountSelected,
  isMetamaskSelected,
  onClickAccountHandler,
  zkAddress
}) => {
  const succinctAddress = getAbbreviatedName(accountAddress, 5, 5);

  const succinctAccountName =
    accountName.length > 12 ? `${accountName?.slice(0, 12)}...` : accountName;
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();

  const { pathname } = useLocation();
  const isMantaPayPage = pathname.includes('/transact');

  const AccountIcon = () =>
    isMetamaskSelected ? (
      <img
        className="ml-1 rounded-full w-6 h-6"
        src={makeBlockie(accountAddress)}
        alt={'blockie address icon'}
      />
    ) : (
      <Identicon
        value={accountAddress}
        size={24}
        theme="polkadot"
        className="px-1"
      />
    );

  if (zkAddress) {
    return (
      <div key={accountAddress}>
        <div className="flex gap-1 items-center mb-4">
          <AccountIcon />
          <div className="text-base">{succinctAccountName}</div>
        </div>

        {isMantaPayPage && (
          <AddressDisplay
            isPrivateAddress
            className="mb-5"
            zkAddress={zkAddress}
            publicAddress={accountAddress}
            disabled={disabled}
          />
        )}
        <AddressDisplay
          zkAddress={zkAddress}
          publicAddress={accountAddress}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <div
      key={accountAddress}
      className={classNames(
        'cursor-pointer flex items-center gap-5 justify-between border border-dark-blue bg-light-blue rounded-lg px-3 text-default w-68 h-16',
        { disabled: disabled }
      )}
      onClick={onClickAccountHandler}>
      <div>
        <div className="flex flex-row items-center gap-3">
          <AccountIcon />
          <div className="flex flex-col">
            <div className="text-base">{succinctAccountName}</div>
            <div className="flex flex-row items-center gap-2 text-default text-opacity-60 text-sm">
              {succinctAddress}
              <CopyPasteIcon
                iconClassName="w-5 h-5"
                textToCopy={accountAddress}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative right-2">
        {isAccountSelected && (
          <Icon className="text-blue-check" name="greenCheck" />
        )}
      </div>
    </div>
  );
};

const AccountSelectDropdown = ({ isMetamaskSelected }) => {
  const { ethAddress } = useMetamask();
  const { txStatus } = useTxStatus();
  const disabled = txStatus?.isProcessing();
  const { selectedAccount, accountList, changeSelectedAccount } = useWallet();

  const isMetamaskEnabled = isMetamaskSelected && ethAddress;
  const onClickAccountHandler = (account) => () => {
    !disabled && changeSelectedAccount(account);
  };
  const evmWalletName = getHasAuthToConnectEVMWalletNameStorage();
  const accountName = useMemo(() => {
    if (evmWalletName === 'metamask') {
      return 'Metamask';
    } else if (evmWalletName === 'subwallet') {
      return 'SubWallet';
    } else if (evmWalletName === 'talisman') {
      return 'Talisman';
    }
  }, [evmWalletName]);
  return isMetamaskEnabled ? (
    <SingleAccountDisplay
      accountName={accountName}
      accountAddress={ethAddress}
      isAccountSelected={true}
      isMetamaskSelected={isMetamaskEnabled}
      onClickAccountHandler={() => {}}
    />
  ) : (
    <div className="flex flex-col gap-5">
      {accountList.map((account: any) => (
        <SingleAccountDisplay
          key={account.address}
          accountName={account.name}
          accountAddress={account.address}
          isAccountSelected={account.address === selectedAccount.address}
          isMetamaskSelected={isMetamaskEnabled}
          onClickAccountHandler={onClickAccountHandler(account)}
          zkAddress={account.zkAddress}
        />
      ))}
    </div>
  );
};

export default AccountSelectDropdown;
