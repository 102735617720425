// @ts-nocheck
import WALLET_NAME from 'constants/WalletConstants';
import Icon from 'components/Icon';
import { useWallet } from 'contexts/walletContext';
import { useMetamask } from 'contexts/metamaskContext';
import { useLocation } from 'react-router-dom';
import { ReactComponent as RecommendedImage } from 'resources/images/recommended-manta-wallet.svg';
import { getSubstrateWallets } from 'utils';
import getErrMsgAfterRemovePathname from 'utils/display/getErrMsgAfterRemovePathname';
import getWalletDisplayName from 'utils/display/getWalletDisplayName';
import { getShouldShowMetamask } from 'utils/display/getShouldShowMetamask';
import { getHasAuthToConnectEVMWalletNameStorage } from 'utils/persistence/connectAuthorizationStorage';

const ConnectWalletBlock = ({
  extensionName,
  walletName,
  isWalletInstalled,
  walletInstallLink,
  walletLogo,
  isWalletEnabled,
  connectHandler
}) => {
  const { walletConnectingErrorMessages } = useWallet();
  const { pathname } = useLocation();
  const isMantaPayPage = pathname.includes('/transact');
  const errorMessage = walletConnectingErrorMessages[extensionName];

  const getDisplayedErrorMessage = () => {
    const talismanExtNotConfiguredString =
      'Talisman extension has not been configured yet. Please continue with onboarding.';
    if (
      extensionName === WALLET_NAME.TALISMAN &&
      errorMessage === talismanExtNotConfiguredString
    ) {
      return 'You have no account in Talisman. Please create one first.';
    }
    return getErrMsgAfterRemovePathname(errorMessage);
  };

  const WalletNameBlock = () => {
    const isMantaWallet = extensionName === WALLET_NAME.MANTA;
    return (
      <div>
        <div className="text-base flex items-center gap-3 leading-5 font-semibold">
          {walletName}
          {isMantaWallet && isMantaPayPage && <RecommendedImage />}
        </div>
        {isMantaPayPage && (
          <div className="text-sm text-logo leading-4 mt-1 opacity-80">
            {isMantaWallet
              ? 'Public Address and zkAddress Combined'
              : 'Public Addresses Only'}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative mt-2 p-4 flex items-center justify-between text-default bg-light-blue rounded-lg w-full">
      <div className="flex flex-row items-center gap-4">
        {walletLogo && typeof walletLogo === 'object' ? (
          <img
            src={walletLogo.src}
            alt={walletLogo.alt}
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <Icon name={walletLogo} className="w-10 h-10 rounded-full" />
        )}
        <WalletNameBlock />
      </div>

      {isWalletEnabled ? (
        <div className="flex items-center justify-center text-xs w-30">
          <div className="flex items-center gap-3">
            <div className="rounded full w-2 h-2 gradient-button" />
            <div className="gradient-text">Connected</div>
          </div>
        </div>
      ) : isWalletInstalled ? (
        <button
          onClick={connectHandler}
          className="rounded-lg gradient-button text-white text-sm w-30 h-10">
          Connect
        </button>
      ) : (
        <a href={walletInstallLink} target="_blank" rel="noreferrer">
          <div className="text-center rounded-lg gradient-button text-white text-sm w-30 h-10 leading-10">
            Install
          </div>
        </a>
      )}

      {errorMessage && (
        <p className="absolute -left-px -bottom-5 flex flex-row gap-2 b-0 text-warning text-xsss">
          <Icon name="information" />
          {getDisplayedErrorMessage()}
        </p>
      )}
    </div>
  );
};

const MetamaskConnectWalletBlock = ({ hideModal }) => {
  const { connectEVMWallet, ethAddress } = useMetamask();
  const connectedEVMWalletName = getHasAuthToConnectEVMWalletNameStorage();
  const metamaskIsInstalled =
    window.ethereum?.isMetaMask &&
    !window.ethereum?.isBraveWallet &&
    !window.ethereum.isTalisman;

  const handleConnectWallet = async () => {
    const isConnected = await connectEVMWallet('metamask');
    isConnected && hideModal();
  };

  return (
    <ConnectWalletBlock
      key={'metamask'}
      walletName={'MetaMask'}
      isWalletInstalled={metamaskIsInstalled}
      walletInstallLink={'https://metamask.io/'}
      walletLogo="metamask"
      isWalletEnabled={!!ethAddress && connectedEVMWalletName === 'metamask'}
      connectHandler={handleConnectWallet}
    />
  );
};

const SubwalletEVMConnectWalletBlock = ({ hideModal }) => {
  const { connectEVMWallet, ethAddress } = useMetamask();
  const connectedEVMWalletName = getHasAuthToConnectEVMWalletNameStorage();
  const subwalletIsInstalled = window.SubWallet;

  const handleConnectWallet = async () => {
    const isConnected = await connectEVMWallet('subwallet');
    isConnected && hideModal();
  };

  return (
    <ConnectWalletBlock
      key={'subwallet'}
      walletName={'SubWallet'}
      isWalletInstalled={subwalletIsInstalled}
      walletInstallLink={'https://www.subwallet.app/download.html'}
      walletLogo="subwallet"
      isWalletEnabled={!!ethAddress && connectedEVMWalletName === 'subwallet'}
      connectHandler={handleConnectWallet}
    />
  );
};
const TalismanEVMConnectWalletBlock = ({ hideModal }) => {
  const { connectEVMWallet, ethAddress } = useMetamask();
  const connectedEVMWalletName = getHasAuthToConnectEVMWalletNameStorage();
  const talismanIsInstalled = window.talismanEth;

  const handleConnectWallet = async () => {
    const isConnected = await connectEVMWallet('talisman');
    isConnected && hideModal();
  };

  return (
    <ConnectWalletBlock
      key={'talisman'}
      walletName={'Talisman'}
      isWalletInstalled={talismanIsInstalled}
      walletInstallLink={'http://talisman.xyz/download'}
      walletLogo="talisman"
      isWalletEnabled={!!ethAddress && connectedEVMWalletName === 'talisman'}
      connectHandler={handleConnectWallet}
    />
  );
};

export const SubstrateConnectWalletBlock = ({
  setIsMetamaskSelected,
  hideModal
}) => {
  const { connectWallet, authedWalletList } = useWallet();
  const substrateWallets = getSubstrateWallets();

  // display Manta Wallet as the first wallet
  const mantaWalletIndex = substrateWallets.findIndex(
    (wallet) => wallet.extensionName === WALLET_NAME.MANTA
  );
  substrateWallets.unshift(substrateWallets.splice(mantaWalletIndex, 1)[0]);
  const handleConnectWallet = (walletName) => async () => {
    const isConnected = await connectWallet(walletName, true, true);
    if (isConnected) {
      setIsMetamaskSelected && setIsMetamaskSelected(false);
      hideModal();
    }
  };

  return substrateWallets.map((wallet) => {
    const isWalletEnabled = authedWalletList.includes(wallet.extensionName);

    return (
      <ConnectWalletBlock
        key={wallet.extensionName}
        extensionName={wallet.extensionName}
        walletName={getWalletDisplayName(wallet.extensionName)}
        isWalletInstalled={wallet.installed}
        walletInstallLink={wallet.installUrl}
        walletLogo={wallet.logo}
        isWalletEnabled={isWalletEnabled}
        connectHandler={handleConnectWallet(wallet.extensionName)}
      />
    );
  });
};

const ConnectWalletModal = ({ setIsMetamaskSelected, hideModal }) => {
  const shouldShowMetamask = getShouldShowMetamask();

  const { pathname } = useLocation();
  const isMantaPayPage = pathname.includes('/calamari/transact');

  return (
    <div className="w-126.5">
      <h1 className="text-xl text-default font-semibold mb-6">
        Connect Wallet
      </h1>
      {isMantaPayPage && (
        <div className="mb-6 text-sm text-default opacity-80">
          For full MantaPay functionality,
          <br />
          both public and zkAddress are required.
        </div>
      )}
      <div className="font-semibold text-sm mt-2">Polkadot Wallets</div>
      <SubstrateConnectWalletBlock
        setIsMetamaskSelected={setIsMetamaskSelected}
        hideModal={hideModal}
      />
      {shouldShowMetamask && (
        <>
          <div className="font-semibold text-sm mt-2">EVM Wallets</div>
          <MetamaskConnectWalletBlock hideModal={hideModal} />
          <SubwalletEVMConnectWalletBlock hideModal={hideModal} />
          <TalismanEVMConnectWalletBlock hideModal={hideModal} />
        </>
      )}
      <p className="flex flex-row gap-2 mt-6 text-default text-xsss">
        <Icon name="information" />
        Already installed? Try refreshing this page
      </p>
    </div>
  );
};

export default ConnectWalletModal;
